.header {
	width: 100%;
	height: 5rem;
	background-color: #044599;
	padding: 0 10%;
}

.header nav {
	height: 100%;
}

.header ul {
	height: 100%;
	list-style: none;
	display: flex;
	padding: 0;
	margin: 0;
	align-items: center;
	justify-content: center;
}

.header li {
	margin: 0 1rem;
	width: 5rem;
}

.header a {
	color: white;
	text-decoration: none;
}

.header a:hover,
.header a:active,
.header a.active {
	color: #95bcf0;
	padding-bottom: 0.25rem;
	border-bottom: 4px solid #95bcf0;
}